import castArray from 'lodash/castArray';
import isArray from 'lodash/isArray';
import { serialize } from 'object-to-formdata';

type Primitive = string | number | boolean;

type Param = Primitive | null | undefined | Param[] | { [key: string]: Param };

type Params = Record<string, Param> | null;

function isPrimitive(value: any): value is Primitive {
  return value !== Object(value);
}

export const stringifyParams = (params?: Params) =>
  JSON.stringify(params, (_, value) => {
    if (value === null || value === '') {
      return undefined;
    }

    return value;
  });

export const encodeParam = (param: Param) =>
  encodeURIComponent(isPrimitive(param) ? (param as Primitive) : stringifyParams(param as Record<string, Param>));

export const normalizeArrayParams = (key: string, params: Param[]) =>
  params.map((param, idx) => `${encodeParam(`${key}[${idx}]`)}=${encodeParam(param)}`).join('&');

export const normalizeParams = (params?: Params) =>
  Object.keys(params || {})
    .filter((key) => params?.[key] !== '' && params?.[key] !== null && params?.[key] !== undefined)
    .map((key) => (isArray(params?.[key]) ? normalizeArrayParams(key, params?.[key]! as Param[]) : `${key}=${encodeParam(params?.[key])}`))
    .join('&');

type BaseTokensResponse = {
  /**
   * Токен, который требуется для обращения к API
   */
  access_token: string;

  /**
   * Тип токена (bearer)
   */
  token_type: string;

  /**
   * Через сколько данный access_token перестанет быть валидным
   */
  expires_in: number;

  /**
   * Токен, с помощью которого можно получать access_token без получения device_code
   */
  refresh_token: string;
};

class BaseApiClient {
  protected baseUrl: string;
  protected baseUrls: string[];
  protected baseUrlIndex = 0;
  protected baseUrlsCount = 1;

  private refreshTokenPromise: Promise<void> | null = null;

  constructor(baseUrl: string | string[]) {
    this.baseUrls = castArray(baseUrl).map((url) =>
      url.startsWith('http') ? url : window.location.protocol.startsWith('http') ? `${window.location.protocol}//${url}` : `http://${url}`,
    );
    this.baseUrlsCount = this.baseUrls.length;

    this.baseUrl = this.baseUrls[this.baseUrlIndex];
  }

  private async getOrRefreshAccessToken() {
    let accessToken = this.getAccessToken();

    if (!accessToken) {
      const refreshToken = this.getRefreshToken();

      if (refreshToken && !this.refreshTokenPromise) {
        this.refreshTokenPromise = this.refreshTokens(refreshToken).then(() => {
          this.refreshTokenPromise = null;
        });
      }

      if (this.refreshTokenPromise) {
        await this.refreshTokenPromise;
        accessToken = this.getAccessToken();
      }
    }

    return accessToken;
  }

  private async request<T>(method: 'GET' | 'POST', url: string, params?: Params, data?: Params): Promise<T> {
    if (!params?.['grant_type']) {
      const accessToken = await this.getOrRefreshAccessToken();

      if (accessToken) {
        params = {
          ...params,
          access_token: accessToken,
        };
      }
    }

    try {
      const response = await fetch(`${this.baseUrl}${url}?${normalizeParams(params)}`, {
        method,
        body: data && serialize(data),
      });

      if (response.status === 401) {
        this.clearTokens();
      }

      const json = await response.json();

      return json as T;
    } catch (ex) {
      const error = (ex as Error).toString();

      if (error.includes('Failed to fetch') && this.baseUrlsCount > 1) {
        this.baseUrlIndex = (this.baseUrlIndex + 1) % this.baseUrlsCount;
        this.baseUrl = this.baseUrls[this.baseUrlIndex];

        return this.request<T>(method, url, params, data);
      }

      return {
        error,
      } as unknown as T;
    }
  }

  protected get<T>(url: string, params?: Params) {
    return this.request<T>('GET', url, params);
  }

  protected post<T>(url: string, data?: Params, params?: Params) {
    return this.request<T>('POST', url, params, data);
  }

  protected async refreshTokens(refresh_token: string): Promise<BaseTokensResponse> {
    throw new Error('not implemented');
  }

  protected getAccessToken(): string {
    throw new Error('not implemented');
  }

  protected getRefreshToken(): string {
    throw new Error('not implemented');
  }

  protected saveTokens({
    access_token,
    refresh_token,
    expires_in,
  }: {
    access_token: string;
    refresh_token: string;
    expires_in: number;
  }): void | Promise<void> {
    throw new Error('not implemented');
  }

  protected clearTokens(): void | Promise<void> {
    throw new Error('not implemented');
  }
}

export default BaseApiClient;

import { Component, ReactNode, RefCallback } from 'react';

export const memoryStore = {
  _data: new Map(),
  get(key: string) {
    if (!key) {
      return null;
    }

    return this._data.get(key) || null;
  },
  set(key: string, data: unknown) {
    if (!key) {
      return;
    }
    return this._data.set(key, data);
  },
};

function scroll(target: HTMLElement | Window, x: number, y: number) {
  if (target instanceof window.Window) {
    target.scrollTo(x, y);
  } else {
    target.scrollLeft = x;
    target.scrollTop = y;
  }
}

function getScrollPosition(target: HTMLElement | Window) {
  if (target instanceof window.Window) {
    return { x: target.scrollX, y: target.scrollY };
  }

  return { x: target.scrollLeft, y: target.scrollTop };
}

type ScrollManagerProps = {
  scrollKey: string;
  scrollStore: typeof memoryStore;
  children?: (props: { connectScrollTarget: RefCallback<HTMLElement> }) => ReactNode;
};

/**
 * Component that will save and restore Window scroll position.
 */
class ScrollManager extends Component<ScrollManagerProps> {
  private _target: HTMLElement | Window;

  constructor(props: ScrollManagerProps) {
    super(props);
    this._target = window;
  }

  static defaultProps = {
    scrollStore: memoryStore,
  };

  connectScrollTarget = (node: HTMLElement) => {
    this._target = node;
  };

  restoreScrollPosition(pos?: { x: number; y: number }) {
    pos = pos || this.props.scrollStore.get(this.props.scrollKey);
    if (this._target) {
      requestAnimationFrame(() => {
        scroll(this._target, pos?.x ?? 0, pos?.y ?? 0);
      });
    }
  }

  saveScrollPosition(key?: string) {
    if (this._target) {
      const pos = getScrollPosition(this._target);
      key = key || this.props.scrollKey;
      this.props.scrollStore.set(key, pos);
    }
  }

  componentDidMount() {
    this.restoreScrollPosition();
  }

  componentWillReceiveProps(nextProps: ScrollManagerProps) {
    if (this.props.scrollKey !== nextProps.scrollKey) {
      this.saveScrollPosition();
    }
  }

  componentDidUpdate(prevProps: ScrollManagerProps) {
    if (this.props.scrollKey !== prevProps.scrollKey) {
      this.restoreScrollPosition();
    }
  }

  componentWillUnmount() {
    this.saveScrollPosition();
  }

  render() {
    const { children = null, ...props } = this.props;
    return children && children({ ...props, connectScrollTarget: this.connectScrollTarget });
  }
}

export default ScrollManager;

import SpottableWrapper from '@enact/spotlight/Spottable';
import Item from '@enact/ui/Item';

import useThrottledCallback from 'hooks/useThrottledCallback';

const SpottableItem = SpottableWrapper(Item);

type Props = {} & React.ComponentProps<typeof SpottableItem>;

const Spottable: React.FC<Props> = ({ onClick, ...props }) => {
  const handleClick = useThrottledCallback<React.MouseEventHandler<HTMLElement>>(
    (e) => {
      onClick?.(e);
    },
    [onClick],
  );

  return <SpottableItem {...props} onClick={handleClick} />;
};

export default Spottable;

export const queryToObject = (query: string) => {
  return Object.fromEntries(new URLSearchParams(query).entries());
};

export const fixProtocol = (url?: string) => {
  return url ? url.replace(/^https?:/, window.location.protocol) : '';
};

export const replaceHostnameUrl = (url?: string, replacement?: string) => {
  return url && replacement ? url.replace(/(https?:\/\/)?([^/]+)(\/.*)?/, `$1${replacement}$3`) : url || '';
};

export const replaceHostname = <T extends string | undefined>(url: T): T => {
  return (url?.replace('{{HOSTNAME}}', window.location.hostname) ?? url) as T;
};

export const fixCDNUrl = (url?: string) => {
  return replaceHostnameUrl(url, replaceHostname(process.env.REACT_APP_CDN_BASE_URL));
};

import { useCallback } from 'react';
import { useThrottledCallback as useBaseThrottledCallback } from 'use-debounce';
import type { Options } from 'use-debounce/lib/useDebouncedCallback';

function useThrottledCallback<T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  deps: unknown[],
  options?: Options & { wait?: number },
) {
  const { wait = 500, ...rest } = options || {};

  return useBaseThrottledCallback(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(callback, deps),
    wait,
    {
      trailing: false,
      ...rest,
    },
  );
}

export default useThrottledCallback;

import React from 'react';

import ScrollPositionManager from 'components/scrollManager';
import Menu from 'containers/menu';
import useLocation from 'hooks/useLocation';

type Props = {
  children: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children, ...rest }) => {
  const location = useLocation();

  return (
    <div className="flex w-screen h-screen overflow-hidden" {...rest}>
      <Menu />

      <ScrollPositionManager scrollKey={location.key}>
        {({ connectScrollTarget }) => (
          <div ref={connectScrollTarget} className="w-full overflow-x-hidden overflow-y-auto">
            {children}
          </div>
        )}
      </ScrollPositionManager>
    </div>
  );
};

export default MainLayout;
